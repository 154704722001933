import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled, { withTheme } from 'styled-components';

import { media } from 'utils/styleUtils';
import GridRow from 'components/grid/gridRow';
import GridItem from 'components/grid/gridItem';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';

const PartnerLink = styled.div`
  display: block;

  /* &:hover,
  &:focus {
    transform: scale(1.05);
  } */
`;

const PartnerLogo = styled(Img)`
  width: 100px;
  height: 100px;
  margin: 0 auto;

  ${media.screenS`
    width: 160px;
    height: 160px;
  `}
`;

const HomepagePartners = ({ title, subtitle, list, theme }) => {
  return (
    <GridRow
      title={<Heading rank="h2">{title}</Heading>}
      subtitle={<Text color={theme.grey}>{subtitle}</Text>}
      gridWrapper="ul"
      gridItems={
        list && list.edges.map((partner) => {
          const { node: { frontmatter: {
            path,
            teaser: {
              title: teaserTitle, image,
            },
          } } } = partner;

          return (
            <GridItem key={teaserTitle} as="li" width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]} mb={[3, 3, 5]} alignItems="center">
              <PartnerLink to={path}>
                <PartnerLogo fluid={image.src.childImageSharp.fluid} alt={image.alt} />
              </PartnerLink>
            </GridItem>
          );
        })
      }
      cta={<Button to="/partners" as={Link}>View all partners</Button>}
    />
  );
};

HomepagePartners.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  list: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(HomepagePartners);
