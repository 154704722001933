import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { withTheme } from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import { media } from 'utils/styleUtils';
import Container from 'components/grid/container';
import MaxWidth from 'components/grid/maxWidth';
import ToggleVisibility from 'components/responsive/toggleVisibility';
import GreenPatternBg from 'components/patterns/greenPatternBg';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import { HelpIcon } from 'components/icons/icons';

const IconContainer = styled.div`
  height: 200px;
  width: 200px;
  float: right;

  ${media.screenL`
    height: 300px;
    width: 300px;
  `}
`;

const HelpCentreCta = ({ theme }) => {
  return (
    <GreenPatternBg as={Box} py={[8, 8, `100px`]}>
      <Container>
        <Flex flexWrap="wrap" alignItems="center">
          <Box width={[1, 1, 8 / 12]} pr={[0, 0, 10]}>
            <MaxWidth>
              <Heading rank="h2" style="h1" margin="0">Help Centre</Heading>
              <Box my={[3, 3, 5]}>
                <Text style="h4">Find video tutorials, feature overviews and answers to commonly asked questions, as well as contact details in our Help Centre.</Text>
              </Box>
              <Button to="/help-centre" as={Link} variant="secondary">Visit the Help Centre</Button>
            </MaxWidth>
          </Box>

          <Box width={[1, 1, 4 / 12]}>
            <ToggleVisibility maxWidth={theme.screenM}>
              <IconContainer>
                <HelpIcon fill={theme.white} />
              </IconContainer>
            </ToggleVisibility>
          </Box>
        </Flex>
      </Container>
    </GreenPatternBg>
  );
};

HelpCentreCta.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(HelpCentreCta);
