import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box } from '@rebass/grid';

import HomepageHero from 'components/heros/homepageHero';
import HomepageFeatures from 'components/pages/index/homepageFeatures';
import HomepageUsers from 'components/pages/index/homepageUsers';
import HomepageAbout from 'components/pages/index/homepageAbout';
import HomepagePartners from 'components/pages/index/homepagePartners';
import HelpCentreCta from 'components/ctas/helpCentreCta';
import SignUpCta from 'components/ctas/signUpCta';

const GreyContainer = styled(Box)`
  background-color: ${p => p.theme.greyBg};
`;

const IndexPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter: {
      hero,
      features,
      users,
      about,
      partners,
    } },
    featuresList,
    usersList,
    partnersList,
  } = data;

  return (
    <React.Fragment>
      <HomepageHero data={hero} />

      <HomepageFeatures
        title={features.title}
        subtitle={features.subtitle}
        list={featuresList}
      />

      <GreyContainer>
        <HomepageUsers
          title={users.title}
          subtitle={users.subtitle}
          list={usersList}
        />
      </GreyContainer>

      {/* <PricingContainer>
        <Container>
          <Heading rank="h2">Pricing</Heading>
        </Container>
      </PricingContainer> */}

      <HomepageAbout data={about} />

      <GreyContainer>
        <HomepagePartners 
          title={partners.title}
          subtitle={partners.subtitle}
          list={partnersList} 
        />
      </GreyContainer>

      <HelpCentreCta />

      <SignUpCta />
    </React.Fragment>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      frontmatter {
        hero {
          title
          subtitle
          primaryCtaHref
          primaryCtaText
          secondaryCtaHref
          secondaryCtaText
        }
        features {
          title
          subtitle
        }
        users: whoIsItFor {
          title
          subtitle
        }
        about {
          title
          subtitle
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1140) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            alt
          }
        }
        partners {
          title
          subtitle
        }
      }
    }
    featuresList: allMarkdownRemark(
      filter: {frontmatter: { type: {eq: "feature"} teaser: {homepage: {eq: true}}}},
      sort: {fields: [frontmatter___teaser___order], order: ASC},
    ) {
      edges {
        node {
          ...BasicTeaserFragment
        }
      }
    }
    usersList: allMarkdownRemark(
      filter: {frontmatter: { type: {eq: "user"} teaser: {homepage: {eq: true}}}},
      sort: {fields: [frontmatter___teaser___order], order: ASC},
    ) {
      edges {
        node {
          ...BasicTeaserFragment
        }
      }
    }
    partnersList: allMarkdownRemark(
      filter: {frontmatter: { type: {eq: "partner"} teaser: {homepage: {eq: true}}}},
      sort: {fields: [frontmatter___teaser___order], order: ASC},
    ) {
      edges {
        node {
          ...BasicTeaserFragment
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;
