import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import renderHTML from 'react-render-html';

import { media } from 'utils/styleUtils';
import Container from 'components/grid/container';
import FullHeightHero from 'components/heros/fullHeightHero';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import ToggleVisibility from 'components/responsive/toggleVisibility';
import Browser from 'components/images/browser';
import { HeroBg } from 'components/icons/icons';

const HeroBgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  ${media.screenXL`
    bottom: 0;
  `}
`;

const Reorder = styled(Flex)`
  ${media.screenXL`
    flex-direction: row-reverse;
  `}
`;

const HeroText = styled.div`
  text-align: center;
  position: relative;
  z-index: 1;

  ${media.screenXL`
    text-align: left;
  `}
`;

const Buttons = styled.div`
  ${media.screenS`
    display: inline-block;
  `}
`;

const OrText = styled.i`
  color: ${p => p.theme.primaryLightText};
  font-weight: 500;
`;

const HomepageHero = ({ theme, data }) => {
  const {
    title,
    subtitle,
    primaryCtaHref,
    primaryCtaText,
    secondaryCtaHref,
    secondaryCtaText,
  } = data;

  return (
    <FullHeightHero backgroundColor={theme.primaryLight}>
      <Container py={[5, 5, 10]}>
        <HeroBgContainer>
          <HeroBg height="100%" width="100%" fill="transparent" />
        </HeroBgContainer>

        <Reorder flexWrap="wrap" alignItems="center">
          <Box width={[1, 1, 1, 1, 5 / 12]}>
            <Box pb={[6, 6, 10, 10, 0]}>
              <Browser>
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                  src="https://player.vimeo.com/video/375882838"
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </Browser>
            </Box>
          </Box>

          <Box width={[1, 1, 1, 1, 7 / 12]} pr={[0, 0, 0, 0, 10]}>
            <HeroText>
              <Heading rank="h1" style="h1" margin="0">{renderHTML(title)}</Heading>
              <Box py={[5, 5, 5, 5, 10]}>
                <Text style="h4" margin="0" color={theme.primaryLightText}>{renderHTML(subtitle)}</Text>
              </Box>

              <Buttons>
                <ToggleVisibility minWidth={theme.screenS}>
                  <Flex alignItems="center" flexDirection="column">
                    <Button as="a" href={primaryCtaHref} width="100%">{primaryCtaText}</Button>
                    <Box mb="3" />
                    <Button as="a" href={secondaryCtaHref} variant="secondary" width="100%">{secondaryCtaText}</Button>
                  </Flex>
                </ToggleVisibility>

                <ToggleVisibility maxWidth={theme.screenS}>
                  <Flex alignItems="center">
                    <Button as="a" href={primaryCtaHref}>{primaryCtaText}</Button>
                    <Box mx="2"><OrText>or</OrText></Box>
                    <Button as="a" href={secondaryCtaHref} variant="secondary">{secondaryCtaText}</Button>
                  </Flex>
                </ToggleVisibility>
              </Buttons>
            </HeroText>
          </Box>
        </Reorder>
      </Container>
    </FullHeightHero>
  );
};

HomepageHero.propTypes = {
  theme: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withTheme(HomepageHero);
