import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { withTheme } from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import renderHtml from 'react-render-html';

import Container from 'components/grid/container';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';
import BasicLink from 'components/links/basicLink';

const HomepageAbout = ({ data, theme }) => {
  const { title, subtitle, image } = data;

  return (
    <Box py={[8, 8, `100px`]}>
      <Container>
        <Flex flexWrap="wrap" alignItems="center">
          <Box width={[1, 1, 1, 4 / 12]}>
            <Heading rank="h2" margin="0">{title}</Heading>
            <Box py={5}>
              <Text style="h4" margin="0" weight="normal" color={theme.grey}>{renderHtml(subtitle)}</Text>
            </Box>
            <Text style="h4" margin="0"><BasicLink to="/about">Learn more about us</BasicLink></Text>
          </Box>

          <Box width={[1, 1, 1, 8 / 12]} pl={[0, 0, 0, 10]} pt={[5, 5, 10, 0]}>
            <Img fluid={image.src.childImageSharp.fluid} alt={image.alt} />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

HomepageAbout.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(HomepageAbout);
