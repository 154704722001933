import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import GridRow from 'components/grid/gridRow';
import GridItem from 'components/grid/gridItem';
import Heading from 'components/typography/heading';
import Text from 'components/typography/text';
import BasicTeaser from 'components/teasers/basicTeaser';

const HomepageFeatures = ({ title, subtitle, list, theme }) => {
  return (
    <GridRow
      title={<Heading rank="h2">{title}</Heading>}
      subtitle={<Text color={theme.grey}>{subtitle}</Text>}
      gridWrapper="ul"
      gridItems={
        list && list.edges.map((user) => {
          const { node: { frontmatter: {
            path,
            teaser: {
              title: teaserTitle, subtitle: teaserSubtitle, icon,
            },
          } } } = user;

          return (
            <GridItem key={path} as="li">
              <BasicTeaser
                title={teaserTitle}
                subtitle={teaserSubtitle}
                icon={icon}
                linkTo={path}
              />
            </GridItem>
          );
        })
      }
    />
  );
};

HomepageFeatures.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  list: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(HomepageFeatures);
